@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .writing-mode {
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
  }
  .keen-slider__slide {
  }
  .keen-slider {
  }
  .orange-gradient-underline {
    background-image: linear-gradient(to right, #f1aa35, #e98768);
    background-size: 100% 10%;
    background-repeat: no-repeat;
    background-position: bottom;
    display: table;
  }
  .slideshow {
    -webkit-animation: loop-slide 20s infinite linear 1s both;
    animation: loop-slide 20s infinite linear 1s both;
  }
  @-webkit-keyframes loop-slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes loop-slide {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  .markdown {
  }

  /* start ReactModal buzzkuri sp header */
  .fedeIn-modal .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }
  .fedeIn-modal .ReactModal__Overlay--after-open {
    opacity: 1;
  }
  .fedeIn-modal .ReactModal__Overlay--before-close {
    opacity: 0;
  }
  .easing-fade {
    transition: all 0.6s cubic-bezier(0.43, 0.05, 0.17, 1);
    transition-delay: 0.1s;
    opacity: 0;
    transform: translateY(1rem);
    will-change: transform, opacity;
  }
  .ReactModal__Overlay--after-open .easing-fade {
    opacity: 1;
    transform: translateY(0);
  }
  .easing-up {
    transition: all 0.6s cubic-bezier(0.43, 0.05, 0.17, 1);
    opacity: 0;
    transition-delay: 0.2s;
    transform: translateY(150%);
    will-change: transform, opacity;
    transition-property: transform, opacity;
  }
  .ReactModal__Overlay--after-open .easing-up {
    opacity: 1;
    transform: translateY(0);
  }
  .easing-up__request {
    transition: all 0.6s cubic-bezier(0.43, 0.05, 0.17, 1);
    opacity: 0;
    transition-delay: 0.6s;
    transform: translateY(150%);
    will-change: transform, opacity;
  }
  .ReactModal__Overlay--after-open .easing-up__request {
    opacity: 1;
    transform: translateY(0);
  }
  .easing-up__txt {
    transition: all 0.6s cubic-bezier(0.43, 0.05, 0.17, 1);
    opacity: 0;
    transition-delay: 0.2s;
    transform: translateY(30%);
    will-change: transform, opacity;
  }
  .ReactModal__Overlay--after-open .easing-up__txt {
    opacity: 1;
    transform: translateY(0);
  }
  /* end ReactModal buzzkuri sp header */

  .three-line-limit {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* 制限したい行数が3の場合 */
    overflow: hidden;
  }
  @media all and (-ms-high-contrast: none) {
    .three-line-limit {
      color: #333333;
      height: calc(1.5em * 3); /* 制限したい行数が2の場合 */
      line-height: 1.5em; /* heightと同じ値にする */
      overflow: hidden;
      position: relative;
      word-wrap: break-word;
    }
    /* p span {
      margin-right: 1em;
    } */
    .three-line-limit::before {
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        #f7f6f5 30% /* 背景色 */
      );
      bottom: 0;
      content: '…';
      padding-left: 1em;
      position: absolute;
      right: 0;
      margin-right: 1em;
    }
    .three-line-limit::after {
      background: #f7f6f5; /* 背景色 */
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
  .gray-dotted {
    background-image: repeating-linear-gradient(
        -2deg,
        #d8d8d8,
        #d8d8d8 3px,
        transparent 3px,
        transparent 8px,
        #d8d8d8 8px
      ),
      repeating-linear-gradient(
        88deg,
        #d8d8d8,
        #d8d8d8 3px,
        transparent 3px,
        transparent 8px,
        #d8d8d8 8px
      ),
      repeating-linear-gradient(
        178deg,
        #d8d8d8,
        #d8d8d8 3px,
        transparent 3px,
        transparent 8px,
        #d8d8d8 8px
      ),
      repeating-linear-gradient(
        268deg,
        #d8d8d8,
        #d8d8d8 3px,
        transparent 3px,
        transparent 8px,
        #d8d8d8 8px
      );
    background-size: 0, 100% 3px;
    background-position: 0 0, 0 0, 100% 0, 0 100%;
    background-repeat: no-repeat;
  }
  .c-arrow {
    position: relative;
  }
  .c-arrow .line {
    width: 100%;
    height: 1px;
    background-color: #707070;
    position: absolute;
    top: 0;
    left: 0;
  }
  .c-arrow .chevron {
    border-right: 1px solid #707070;
    /* border-bottom: 3px solid #707070; */
    width: 8px;
    height: 8px;
    transform: rotate(-45deg);
    position: absolute;
    top: -3.5px;
    right: 0.3vw;
  }

  .footer-gradient-border::after {
    content: '';
    width: 2px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to right, #f6a800, #f57916);
  }
}

/* start mavonEditor */
@import './github-markdown.min.css';
/* end mavonEditor */
@import './ie11.css';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-weight: normal;
  background-color: white;
}

#__next {
  height: 100%;
}

.loupe-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  font-size: 30px;
  position: relative;
  cursor: pointer;
}

.loupe-icon span {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  width: 56%;
  height: 56%;
  border-radius: 50%;
  margin: -0.28em 0 0 -0.28em;
  border: 0.12em solid #707070;
}
.loupe-icon span::after {
  position: absolute;
  display: block;
  content: '';
  top: 50%;
  left: 50%;
  width: 72%;
  height: 36%;
  background: #707070;
  margin: 0.2em 0 0 0.14em;
  transform: rotate(45deg);
}

.dli-close {
  display: inline-block;
  vertical-align: middle;
  color: #333;
  line-height: 1;
  width: 1em;
  height: 0.1em;
  background: currentColor;
  border-radius: 0.1em;
  position: relative;
  transform: rotate(45deg);
}

.dli-close::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  border-radius: inherit;
  transform: rotate(90deg);
}

/* big-react-calender override start */

.rbc-day-slot > .rbc-events-container {
  margin-right: 5px !important;
}

/* 重複予定の非表示 */
/* .rbc-event {
  left: 3% !important;
} */

.rbc-slot-selection {
  background-color: #bebebe !important;
  color: white !important;
}

.rbc-slot-selecting {
  cursor: ns-resize !important;
}

.rbc-time-header-content,
.rbc-header,
.rbc-time-view,
.rbc-timeslot-group,
.rbc-time-content,
.rbc-day-slot .rbc-time-slot {
  border: none !important;
}

.rbc-day-slot .rbc-timeslot-group {
  border-top: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
}

.rbc-day-slot {
  background-color: #fbfbfb;
}

.rbc-time-content {
  overflow-y: visible !important;
}

.rbc-time-gutter {
  text-align: right;
  font-size: 13px;
  color: #999999;
  margin-top: -10px;
}

/* cellのheight size変更すると各予定の位置ずれが起きる */
.rbc-timeslot-group {
  min-height: 43px !important;
}

.rbc-day-slot:nth-child(2) .rbc-timeslot-group {
  border-left: 1px solid #ddd !important;
}

.rbc-header span span {
  color: #333333;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
}

.rbc-time-header {
  margin-bottom: 10px;
}

.rbc-time-content > * + * > * {
  border-left: none !important;
}

.rbc-day-disalbed {
  cursor: not-allowed;
  background-color: #efeaea87;
}

.rbc-day-slot .rbc-timeslot-group.rbc-timeslot-group-last {
  border-top: 1px solid #ddd !important;
  border-left: none !important;
  border-right: none !important;
  background-color: white !important;
}

/* big-react-calender override end */

.th-sticky::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-right: 2px solid #e7e7e7;
}

/* input zoom disable */
/* 下記参考に追加 */
/* https://themightymo.com/iphone-safari-ios-zoom-on-text-field/ */

/* selectで効かないので使わない */
/* @media screen and (max-width: 767px) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }
} */
