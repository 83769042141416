@media all and (-ms-high-contrast: none) {
  .bg-\[\#F0E130\] {
    background-color: rgba(240, 225, 48, 1);
  }

  .bg-\[\#EFD031\] {
    background-color: rgba(239, 208, 49, 1);
  }

  .bg-\[\#EEC332\] {
    background-color: rgba(238, 195, 50, 1);
  }

  .bg-\[\#EDB833\] {
    background-color: rgba(237, 184, 51, 1);
  }

  .bg-\[\#ECA934\] {
    background-color: rgba(236, 169, 52, 1);
  }

  .bg-\[\#EB9D35\] {
    background-color: rgba(235, 157, 53, 1);
  }

  .bg-\[\#EA9136\] {
    background-color: rgba(234, 145, 54, 1);
  }

  .bg-\[\#E98336\] {
    background-color: rgba(233, 131, 54, 1);
  }

  .bg-\[\#E97838\] {
    background-color: rgba(233, 120, 56, 1);
  }

  .bg-\[\#E76A38\] {
    background-color: rgba(231, 106, 56, 1);
  }

  .bg-\[\#E6553A\] {
    background-color: rgba(230, 85, 58, 1);
  }

  .bg-\[\#CCCCCC\] {
    background-color: rgba(204, 204, 204, 1);
  }

  .bg-gray {
    background-color: rgba(247, 247, 247, 1);
  }

  .bg-white {
    background-color: rgba(255, 255, 255, 1);
  }

  .bg-red {
    background-color: rgba(230, 85, 58, 1);
  }

  .bg-primary {
    background-color: rgba(235, 132, 53, 1);
  }

  .bg-green {
    background-color: rgba(90, 195, 130, 1);
  }

  .bg-lightMidGray {
    background-color: rgba(153, 153, 153, 1);
  }

  .bg-inputGray {
    background-color: rgba(224, 224, 224, 1);
  }

  .bg-kabosu {
    background-color: rgba(255, 246, 226, 1);
  }

  .bg-paleGray {
    background-color: rgba(231, 231, 231, 1);
  }

  .bg-\[\#F7F6F5\] {
    background-color: rgba(247, 246, 245, 1);
  }

  .bg-\[\#F7F7F7\] {
    background-color: rgba(247, 247, 247, 1);
  }

  .bg-\[\#f7f7f7\] {
    background-color: rgba(247, 247, 247, 1);
  }

  .bg-yellow {
    background-color: rgba(240, 180, 48, 1);
  }

  .bg-paleSpGray {
    background-color: rgba(240, 240, 240, 1);
  }

  .bg-lightAmber {
    background-color: rgba(255, 215, 128, 1);
  }

  .bg-\[\#F0B430\] {
    background-color: rgba(240, 180, 48, 1);
  }

  .bg-\[\#c5c5c5\] {
    background-color: rgba(197, 197, 197, 1);
  }

  .bg-\[\#dd3e2c\] {
    background-color: rgba(221, 62, 44, 1);
  }

  .bg-black {
    background-color: rgba(0, 0, 0, 1);
  }

  .bg-\[\#EB8435\] {
    background-color: rgba(235, 132, 53, 1);
  }

  .bg-\[\#FFF2DD\] {
    background-color: rgba(255, 242, 221, 1);
  }

  .bg-\[\#666666\] {
    background-color: rgba(102, 102, 102, 1);
  }
  .hover\:bg-whitesmoke:hover {
    background-color: rgba(245, 245, 245, 1);
  }

  .text-menuTextGray {
    color: rgba(74, 74, 74, 1);
  }

  .text-darkBlue {
    color: rgba(50, 115, 220, 1);
  }

  .text-summerSky {
    color: rgba(50, 152, 220, 1);
  }

  .text-darkGray {
    color: rgba(51, 51, 51, 1);
  }

  .text-dark1 {
    color: rgba(21, 21, 21, 1);
  }

  .text-lightMidGray {
    color: rgba(153, 153, 153, 1);
  }

  .text-midGray {
    color: rgba(102, 102, 102, 1);
  }

  .text-primary {
    color: rgba(235, 132, 53, 1);
  }

  .text-red {
    color: rgba(230, 85, 58, 1);
  }

  .text-white {
    color: rgba(255, 255, 255, 1);
  }

  .text-error {
    color: rgba(169, 68, 66, 1);
  }

  .text-\[\#666666\] {
    color: rgba(102, 102, 102, 1);
  }

  .text-inputGray {
    color: rgba(224, 224, 224, 1);
  }

  .text-achromaticGray {
    color: rgba(140, 140, 140, 1);
  }

  .text-\[\#333333\] {
    color: rgba(51, 51, 51, 1);
  }

  .text-\[\#8B8B8B\] {
    color: rgba(139, 139, 139, 1);
  }

  .text-\[\#FFFFFF\] {
    color: rgba(255, 255, 255, 1);
  }

  .text-\[\#EF9B5B\] {
    color: rgba(239, 155, 91, 1);
  }

  .text-\[\#2C2C2C\] {
    color: rgba(44, 44, 44, 1);
  }

  .text-\[\#808080\] {
    color: rgba(128, 128, 128, 1);
  }

  .text-\[\#666\] {
    color: rgba(102, 102, 102, 1);
  }

  .text-\[\#000000\] {
    color: rgba(0, 0, 0, 1);
  }

  .text-black {
    color: rgba(0, 0, 0, 1);
  }

  .text-\[\#333\] {
    color: rgba(51, 51, 51, 1);
  }

  .text-\[\#999999\] {
    color: rgba(153, 153, 153, 1);
  }

  .text-\[\#EB8435\] {
    color: rgba(235, 132, 53, 1);
  }

  .text-\[\#E5573C\] {
    color: rgba(229, 87, 60, 1);
  }

  .text-\[\#F2B247\] {
    color: rgba(242, 178, 71, 1);
  }

  .text-\[\#707070\] {
    color: rgba(112, 112, 112, 1);
  }

  .text-\[\#F0B430\] {
    color: rgba(240, 180, 48, 1);
  }

  .hover\:text-menuHover:hover {
    color: rgba(54, 54, 54, 1);
  }

  .hover\:text-\[\#e5573c\]:hover {
    color: rgba(229, 87, 60, 1);
  }

  .hover\:text-\[\#e6553a\]:hover {
    color: rgba(230, 85, 58, 1);
  }

  .border-menuGray {
    border-color: rgba(219, 219, 219, 1);
  }

  .border-dark1 {
    border-color: rgba(21, 21, 21, 1);
  }

  .border-paleGray {
    border-color: rgba(231, 231, 231, 1);
  }

  .border-checkGreen {
    border-color: rgba(33, 186, 63, 1);
  }

  .border-midGray {
    border-color: rgba(102, 102, 102, 1);
  }

  .border-error {
    border-color: rgba(169, 68, 66, 1);
  }

  .border-inputGray {
    border-color: rgba(224, 224, 224, 1);
  }

  .border-primary {
    border-color: rgba(235, 132, 53, 1);
  }

  .border-lightMidGray {
    border-color: rgba(153, 153, 153, 1);
  }

  .border-yellow {
    border-color: rgba(240, 180, 48, 1);
  }

  .border-\[\#E7E7E7\] {
    border-color: rgba(231, 231, 231, 1);
  }

  .border-red {
    border-color: rgba(230, 85, 58, 1);
  }

  .border-\[\#f0b430\] {
    border-color: rgba(240, 180, 48, 1);
  }

  .border-\[\#F0B430\] {
    border-color: rgba(240, 180, 48, 1);
  }

  .border-\[\#999999\] {
    border-color: rgba(153, 153, 153, 1);
  }

  .border-\[\#F0F0F0\] {
    border-color: rgba(240, 240, 240, 1);
  }

  .border-white {
    border-color: rgba(255, 255, 255, 1);
  }

  .border-\[\#F7F7F7\] {
    border-color: rgba(247, 247, 247, 1);
  }

  .border-\[\#E5573C\] {
    border-color: rgba(229, 87, 60, 1);
  }

  .border-\[\#666666\] {
    border-color: rgba(102, 102, 102, 1);
  }

  .border-\[\#EB8435\] {
    border-color: rgba(235, 132, 53, 1);
  }
  .border-\[\#337ab7\] {
    border-color: #337ab7;
  }

  .before\:border-\[\#333333\]::before {
    content: '';
    border-color: rgba(51, 51, 51, 1);
  }

  .before\:border-\[\#2C2C2C\]::before {
    content: '';
    border-color: rgba(44, 44, 44, 1);
  }

  .before\:border-white::before {
    content: '';
    border-color: rgba(255, 255, 255, 1);
  }
  .disabled\:border-dark1:disabled {
    border-color: rgba(21, 21, 21, 1);
  }

  .translate-x-\[315px\] {
    transform: translateX(315px);
  }

  .-rotate-45 {
    transform: rotate(-45deg);
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .rotate-0 {
    transform: rotate(0deg);
  }

  .rotate-\[135deg\] {
    transform: rotate(135deg);
  }

  .rotate-\[-135deg\] {
    transform: rotate(-135deg);
  }

  .rotate-90 {
    transform: rotate(90deg);
  }

  .-rotate-90 {
    transform: rotate(-90deg);
  }

  .skew-x-\[-16deg\] {
    transform: rotate(-16deg);
  }

  .-skew-x-12 {
    transform: rotate(-12deg);
  }

  .before\:rotate-\[-135deg\]::before {
    content: '';
    transform: rotate(-135deg);
  }

  .before\:rotate-\[135deg\]::before {
    content: '';
    transform: rotate(135deg);
  }

  .before\:rotate-\[45deg\]::before {
    content: '';
    transform: rotate(45deg);
  }

  .scale-90 {
    transform: scaleX(0.9) scaleY(0.9);
  }

  .shadow-30px {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.16);
  }

  .shadow-button {
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.22);
  }

  .shadow-light {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }

  .shadow-toppage {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
  }

  .shadow-20px {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }

  .blur {
    filter: blur(8px);
  }
  .before\:blur-\[3vw\]::before {
    content: '';
    filter: blur(3vw);
  }

  .bg-gradient-to-r.from-primaryTo {
    background-image: linear-gradient(to right, #f0b430, #e6553a);
  }
  .bg-gradient-to-b.from-primaryTo {
    background-image: linear-gradient(to bottom, #f0b430, #e6553a);
  }
  .bg-gradient-to-br.from-primaryTo {
    background-image: linear-gradient(to bottom right, #f0b430, #e6553a);
  }
  .bg-gradient-to-t.from-primaryTo {
    background-image: linear-gradient(to top, #f0b430, #e6553a);
  }
  .bg-gradient-to-t.from-primaryFrom {
    background-image: linear-gradient(to top, #e6553a, #f0b430);
  }
  .from-\[\#FFFDEF\] {
    background-image: linear-gradient(to bottom, #fffdef, #fffdef);
  }

  .before\:bg-gradient-to-t::before {
    content: '';
    background-image: linear-gradient(to top, #f0b430, #e6553a);
  }

  .lg\:scale-100 {
    transform: scaleX(1) scaleY(1);
  }

  .lg\:shadow-none {
    box-shadow: 0 0 #0000;
  }

  .lg\:rotate-0 {
    transform: rotate(0deg);
  }

  .lg\:rotate-180 {
    transform: rotate(180deg);
  }

  .lg\:scale-75 {
    transform: scaleX(0.75) scaleY(0.75);
  }

  .lg\:border-\[\#F0B32F\] {
    border-color: rgba(240, 179, 47, 1);
  }

  .lg\:bg-white {
    background-color: rgba(255, 255, 255, 1);
  }
  .lg\:bg-\[\#ffffff66\] {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .lg\:bg-kabosu {
    background-color: rgba(255, 246, 226, 1);
  }

  .lg\:text-\[\#FAFAFA\] {
    color: rgba(250, 250, 250, 1);
  }
  .lg\:hover\:border-\[\#337ab7\]:hover {
    border-color: #337ab7;
  }
  .lg\:hover\:bg-\[\#ffffff66\]:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
